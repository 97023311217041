.footer{
  margin-top: auto; 
  width:100%;
  background-color: black;
  color: white;
}

.text{
  font-size: 9px;
}

.socials{
  display:flex;
  justify-content: center;
  margin: auto;
  padding-bottom: 1rem;
}

.content{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
}