.home{
    display: flex;
}
.heading{
    text-align: center;
    border-bottom: solid black;
    border-width: medium;
}

.heading2{
    text-align: center;
    border: solid black;
    border-width: medium;
}
.productContainer{
    display: flex;
    padding: 20px;
    /* flex-wrap set to wrap so items dont show up in a single line only*/
    flex-wrap: wrap;
    /* create space between items */
    justify-content: space-around;
    margin: auto;
}

