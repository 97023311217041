.breakout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
  
.breakout .leftSide {
    display: flex;
    flex-grow: 2;
    width: 45%;
    max-width: 45%;
    padding: 10px;
}
  
.breakout .rightSide {
    height: 100%;
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.border {
    
    border: 2px solid black !important;
  }

  @media only screen and (max-width: 900px) {
    .breakout {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .breakout .leftSide {
        display: flex;
        flex-grow: 2;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        background-color: black;
        margin-top: 20px;
    }
    .border {
    
        border: 2px solid black !important;
      }
    
}