.home{
    display: flex;
}

.products{
    width: 28%;
    margin: 20px;
    
}

.productContainer{
    display: flex;
    padding: 20px;
    /* flex-wrap set to wrap so items dont show up in a single line only*/
    flex-wrap: wrap;
    /* create space between items */
    justify-content: space-around;
}

  @media only screen and (max-width: 1000px) {
    .products{
        width: 40%;
    }
  }
  @media only screen and (max-width: 800px) {
    .products{
        width: 100% ;
    }
  }