.app{
  min-height: 100vh;
  display:flex;
  flex-direction: column;
}
a {
    text-decoration: none !important;
    color: inherit !important;
  }
  @media (max-width: 771px){
    .title {
        font-size: 18px !important;
      }
    
      .productContainer {
        width: 58%;
        padding: 0;
      }
    
      .search {
        display: none !important;
      }
    
      .products {
        width: 100%;
      }
}