.home {
  display: flex;
}

.summary{
    width: 30%;
}

.cartContainer {
    display: flex;
    width: 78%;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin:0;
  }

  .filters {
    background-color: #343a40;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
    height: 86vh;
  }
